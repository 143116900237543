<!-- Alert Banner Component -->

<template>
  <!-- Main -->
  <div>

    <!-- Snackbar Component -->
    <v-snackbar v-model="displayBanner" tile vertical width="100%" elevation="0" :timeout="-1" :color="alertsDataColor">
      <template v-slot:action="{ attrs }">

        <!-- Carousel Component -->
        <v-carousel :cycle="isCycling" :show-arrows="false" hide-delimiter-background
          :hide-delimiters="!alertsData.length" :light="setOutsideLightThemeForInfoBanner()" delimiter-icon="mdi-minus"
          height="100%" @change="setOutsideBannerColor($event)">

          <v-carousel-item v-for="(alert, i) in alertsData" :key="i" @mouseover="stopCycling" @mouseleave="startCycling" @touchstart="stopCycling" @touchend="startCycling">

            <!-- Alert Component -->
            <v-alert :light="setLightThemeForInfoBanner(alert?.AlertType)"
              class="ma-2 ml-7" dense elevation="0" color="transparent">

              <!-- Prepend Icon - Alert Component -->
              <template v-slot:prepend v-if="!$vuetify.breakpoint.xs">

                <!-- If not independence day set prominent icon -->
                <v-icon v-if="!isIndependenceDay(alert?.Heading)" :light="setLightThemeForInfoBanner(alert?.AlertType)" large
                  class="prominent mr-6">mdi-information</v-icon>

                <!-- If independence day set independence day flag -->
                <v-img v-if="isIndependenceDay(alert?.Heading)" src="https://embed.wasteconnections.com/images-widget/flagUSA.gif"
                  alt="Independence Day Flag" class="mr-1" :draggable="false" :max-width="120"></v-img>
              </template>

              <v-row align="center" class="flex-column justify-space-between">

                <!-- Title Section -->
                <v-col align-self="stretch" :class="!$vuetify.breakpoint.xs ? 'max-width-60' : 'max-width-initial'">

                  <!-- If mobile and not independence day set prominent icon -->
                  <v-icon v-if="$vuetify.breakpoint.xs && !isIndependenceDay(alert?.Heading)" :light="setLightThemeForInfoBanner(alert?.AlertType)" large
                    class="prominent mb-6">mdi-information</v-icon>

                  <!-- If mobile and independence day set independence day flag -->
                  <v-img v-if="$vuetify.breakpoint.xs && isIndependenceDay(alert?.Heading)" src="https://embed.wasteconnections.com/images-widget/flagUSA.gif"
                    alt="Independence Day Flag" class="mb-1" :draggable="false" :max-width="70"></v-img>

                  <div class="text-h6">{{ alert?.Heading }}</div>
                  <div v-html="alert?.Message" :class="setLightThemeForInfoBanner(alert?.AlertType)?'a-dark-theme':'a-light-theme'" class="message-height-scroll"></div>
                </v-col>

                <!-- Action Button - Review pickup schedule / pickup-schedule -->
                <v-col class="col-auto mr-auto mb-6">
                  <v-btn depressed outlined class="rounded-lg action-btn"
                    color="secondary" @click="setAlertsCookieToFalse()">
                    <span class="text-capitalize font-weight-bold text-body-2">Got it</span>
                  </v-btn>
                </v-col>

                <!-- Close Button -->
                <v-col class="col-auto ml-auto order-first py-2 px-0">
                  <v-btn v-bind="attrs" rounded :light="!setLightThemeForInfoBanner(alert?.AlertType)"
                    :dark="setLightThemeForInfoBanner(alert?.AlertType)" small depressed
                    @click="setAlertsCookieToFalse()">
                    <span class="text-capitalize font-weight-bold text-body-2">Close</span>
                    <v-icon right size="21px">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-col>

              </v-row>
            </v-alert>

          </v-carousel-item>

        </v-carousel>

      </template>
    </v-snackbar>

  </div>
</template>

<!-- Javascript Section -->
<script>
import i18n from "@/i18n";
export default {
  name: 'AlertBanner',

  props: {
    districtCode: Number,
  },

  data() {
    return {
      i18n: i18n,
      COOKIENAME: process.env.VUE_APP_COOKIE_NAME,
      displayBanner: false,
      isCycling: true,
      alertsData: {},
      alertsDataColor: "rgba(236, 193, 14, 0.80)",
      alertsColors: {
        warning: "rgba(192, 68, 13, 0.82)",
        success: "rgba(5, 104, 40, 0.85)",
        urgent: "rgba(209, 0, 0, 0.75)",
        info: "rgba(236, 193, 14, 0.80)",
      },
    };
  },

  async mounted() {

    // If there are campaign or phone parameters in the url,
    // set all parameters in session storage and/or
    // exchange phone number in html
    this.campaignStorageTracking();
    this.campaignPhoneTracking();

    // If no cookie is set, set cookie to TRUE
    if (!this.$cookies.isKey(this.COOKIENAME)) {
      this.$cookies.set(this.COOKIENAME, 'TRUE', null, window.location.pathname);
    }

    // If the cookie is set to TRUE, call the API
    if (this.$cookies.get(this.COOKIENAME) === 'TRUE') {
      await this.fetchMuleAPIDev();
    }

  },

  methods: {

    // Call the API to obtain all district alerts
    async fetchMuleAPIDev() {
      const RESPONSE = await fetch(`${process.env.VUE_APP_MULE_API_DEV}/${this.districtCode}.json`, {
        method: "GET",
      });

      const DATA = await RESPONSE.json();

      if (DATA[0]?.errorMessage) {
        console.log('The district code you provided is invalid.');
      } else {
        this.setAlerts(DATA[0]);
      }
    },

    // If the api response contains alerts, display them
    setAlerts(apiReponse) {
      if (apiReponse?.DistrictAlerts.length) {
        const districtAlertsSort = apiReponse.DistrictAlerts.sort();
        this.alertsData = districtAlertsSort;
        this.displayBanner = true;
      }
    },

    // Set a cookie to hide the banner
    setAlertsCookieToFalse() {
      this.$cookies.set(this.COOKIENAME, 'FALSE', null, window.location.pathname);
      this.displayBanner = false;
    },

    // Set Session Storage for Campaign/Ads
    campaignStorageTracking() {
      const urlParams = new URLSearchParams(window.parent.location.search);
      let campaignStorage = {};
      const campaignURL = JSON.parse(sessionStorage.getItem("campaignURL")) || {};

      const keys = ["utm_source", "utm_campaign", "utm_medium", "utm_term", "gclid", "fclid", "referrer", "etid"];
      const keysCamelCase = ["utmSource", "utmCampaign", "utmMedium", "utmTerm", "gclid", "fclid", "referrer", "etid"];

      keys.forEach((key, index) => {
        let value = urlParams.get(key);
        campaignStorage[keysCamelCase[index]] = value ? encodeURIComponent(value) : campaignURL[keysCamelCase[index]] || "";
      });

      if (Object.values(campaignStorage).some(Boolean)) {
        sessionStorage.setItem("campaignURL", JSON.stringify(campaignStorage));
      }
    },

    campaignPhoneTracking() {
      // Get the 'pn' parameter from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const pn = urlParams.get('pn');

      // If the 'pn' parameter exists
      if (pn) {
        // Select all divs that contain the phone number
        // Loop through each div and replace the phone number with the 'pn' parameter
        document.querySelectorAll('.button.is-secondary-icon.w-inline-block div:last-child')
          .forEach(div => div.textContent = pn);
      }
    },

    // Set outside banner color
    setOutsideBannerColor(index) {
      const heading = this.alertsData[index].Heading?.toLowerCase();
      const alertType = this.alertsData[index].AlertType?.toLowerCase();

      this.alertsDataColor = this.isIndependenceDay(heading) ? this.alertsColors['urgent'] : this.alertsColors[alertType];
    },

    // Set light theme for info banner
    setLightThemeForInfoBanner(alertType) {
      return alertType?.toLowerCase() === 'info';
    },

    // Set outside light theme for info banner
    setOutsideLightThemeForInfoBanner() {
      return this.alertsDataColor === this.alertsColors.info;
    },

    // Stop the banner cycling
    stopCycling() {
      this.isCycling = false;
    },

    // Start the banner cycling
    startCycling() {
      this.isCycling = true;
    },

    // Check in the header for Independence Day keyword, if true will set banner color and flag image
    isIndependenceDay(heading) {
      const independenceDayKeywords = [
        'independence day',
        'july 4th',
        'fourth of july',
        '4th of july',
        'american independence'
      ];

      return independenceDayKeywords.some(keyword => heading.toLowerCase().includes(keyword));
    }
  }
}
</script>

<!-- Style Section -->
<style scoped>
/* A Tag Theme Color Style */
::v-deep .a-light-theme a {
  color: white;
}

::v-deep .a-dark-theme a {
  color: black;
}

/* Snackbar Style */
::v-deep .v-snack__wrapper {
  max-width: none;
  margin: 0;
  backdrop-filter: blur(12.5px);
}

::v-deep .v-snack__content {
  padding: 0;
}

::v-deep .v-snack__action {
  width: 100%;
  margin: 0 !important;
}

/* Snackbar Button Style */
::v-deep .action-btn {
  background-color: white;
  letter-spacing: initial;
}

/* Text box width limited by Chatbot Desktop Style */
.max-width-60 {
  max-width: 60vw;
}

.max-width-initial {
  max-width: initial;
}

/* Carousel Delimiter Button Style */
::v-deep .v-carousel--hide-delimiter-background .v-carousel__controls {
  align-items: start;
  top: 0;
  width: 66%;
  height: 28px;
  margin-top: 12px;
  margin-left: 26px;
  justify-content: start;
}

::v-deep .v-carousel__controls__item {
  border-radius: 8px;
  width: 65px !important;
  margin: 0 3px !important;
  transform: scaleY(0.5);
}

::v-deep .mdi-minus {
  font-size: 65px !important;
}

/* Snackbar Prepend Icon Style */
.prominent:after {
  opacity: .16 !important;
  transform: scale(1.5) !important;
}

/* Maximum height of scrollable message box Style */
.message-height-scroll {
  max-height: 7.5em;
  overflow-y: auto;
}
</style>